<template>
	<div class="admin_dashboard">

		<Header :title="headerData.Title" :subTitle="headerData.SubTitle" :subTitle2="headerData.SubTitle2" :bgUrl="headerData.bgUrl" :bgUrlLazy="headerData.bgUrlLazy" :offset="headerData.offset" :height="headerData.height" :overlayOpacity="headerData.overlayOpacity" :overlayColor="headerData.overlayColor" :imgBlur="headerData.imgBlur" :transitionRotate="headerData.transitionRotate" />

		<v-row no-gutters class="pa-0 ma-0 d-flex justify-center">

			<v-col :cols="dashboard.cols" :sm="dashboard.sm" :md="dashboard.md" :lg="dashboard.lg" :xl="dashboard.xl">
				<DataTable title="Utilisateurs" type="users" address showExpand :headers="headers.users" :items="customers" :itemKey="customerEntry.id" link="/administration/customer" />
			</v-col>

			<v-col :cols="dashboard.cols" :sm="dashboard.sm" :md="dashboard.md" :lg="dashboard.lg" :xl="dashboard.xl">
				<DataTable title="Entreprises" type="companies" address showExpand :headers="headers.company" :items="companies" :itemKey="customerEntry.id" link="/administration/company" />
			</v-col>

		</v-row>

	</div>
</template>


<script>
	import Header from '@/components/graphics/header';

	import DataTable from '@/components/modules/#global/DataTable';

	export default {
		head: {
			title: {
				inner: "Tableau de Bord - Admin"
			}
		},
		components: {
			DataTable,

			Header
		},
		data() {
			return {
				userInfo: this.$models.user,
				backgroundImage: "https://images.unsplash.com/photo-1509023464722-18d996393ca8?auto=format&fit=crop&w=1950",

				headerData: {

					Title: '',

					SubTitle: "Bienvenue sur l'espace administrateur Katana.",
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "60vh",
					height: "75vh",

					overlayOpacity: "0",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},

				dashboard: {
					cols: 12,
					sm: 12,
					md: 12,
					lg: 6,
					xl: 4,
				},

				customerEntry: {},

				headers: {
					'users': [{
							text: "",
							value: "status",
							width: 50,
						},
						{
							text: "",
							sortable: false,
							value: "avatar",
							width: 50,
						},
						{
							text: "n°",
							value: "nClient",
							width: 65,
						},
						{
							text: "Nom",
							value: "lastName",
							width: 75,
						},
						{
							text: "Prénom",
							value: "firstName",
							width: 0,
						},
						{
							text: "",
							sortable: false,
							value: "manage",
							width: 140,
						},
					],
					'company': [{
						text: "",
						value: "status",
						width: 50,
					},
					{
						text: "",
						sortable: false,
						value: "avatar",
						width: 50,
					},
					{
						text: 'n°',
						value: 'nCompany',
						width: 65,
					},
					{
						text: 'Entreprise',
						value: 'name',
						width: 0,
					},
					{
						text: "",
						sortable: false,
						value: "manage",
						width: 140,
					},
				],
				},

				customers: [],
				companies: [],

				modules: {
					customers: false,
					companies: false
				}
			}
		},

		created() {

			/*var config = {
				method: 'GET',
				headerData: {
					'Accept': 'application/json'
				},
				params: {
					client_id: 'uswpQJXd2jEnD44udd8MRIMzzyRTzxE8IlMaYB-Iyko',
					orientation: 'landscape',
					query: 'mountains'
				}
			};
			axios("https://api.unsplash.com/photos/random", config).then((response) => {

				console.log(response.data)

				this.backgroundImage = response.data.urls.full

			});*/

			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.headerData.Title = `${this.userInfo.firstName},`

				this.$db.collection("companies").doc(doc.data().company).onSnapshot(async () => {
					this.modules.customers = await this.$functions.hasModule(this.userInfo,
						'8JJgD8UzYi1c6H3tukKF');
					this.modules.companies = await this.$functions.hasModule(this.userInfo,
						'MuIS4a0uWMkvYxL2o9v1');
				});

				this.$db.collection("users").onSnapshot((
					res) => {
					this.customers = [];

					res.forEach((document) => {
						this.customers.push({
							...document.data(),
							id: document.id,
						});
					});
				});

				this.$db.collection("companies").onSnapshot((
					res) => {
					this.companies = [];

					res.forEach((document) => {
						this.companies.push({
							...document.data(),
							id: document.id,
						});
					});
				});
			});
		}
	}
</script>